// Type imports
import type {
  AccountType,
  ConfigConfigureOptions,
  Config as IConfig,
  NavigateSignInOptions, OnAuthRequiredCallback,
  OnUpdateCallback,
  StorageAuthData,
  StorageUserData,
  OAuthKitState,
} from './Types'

// Utilities
import { produce } from 'immer'
// eslint-disable-next-line import/no-named-as-default
import MessageBroker from '@vayapin/utils/MessageBroker'
import isValidArray from '@vayapin/utils/isValidArray'
import isValidDate from '@vayapin/utils/isValidDate'
import includes from 'lodash/includes'
import isPresent from '@vayapin/utils/isPresent'
import { isNumGt0 } from './utils'
import handleError from './utils/handleError'
import debugLog from './utils/debugLog'

// Core imports
import Api from './Api'
import Config from './Config'
import OAuthError from './errors/OAuthError'
import PkceHelper from './PkceHelper'
import StorageManager from './StorageManager'
import UrlBuilder from './utils/UrlBuilder'
import { version as pkgVersion } from './version'

const DEFAULT_DATA: OAuthKitState = {
  authData: {},
  userData: {},
  lastActivityAt: undefined,
  lastOAuthAuthorizationCodeFlowAt: undefined,
  accessAllowed: true,
  signedIn: false,
}

/**
 * OAuthKit module to handle the core logic of authentication.
 */
export class OAuthKit {
  queuePrefix = 'oauthkit'
  version = pkgVersion
  state: OAuthKitState = DEFAULT_DATA

  /**
   * Merges the default config with your passed configuration
   * object and saves it as current config.
   * @see {@link Config.configure}
   */
  configure(configuration: ConfigConfigureOptions, reset = false): IConfig {
    debugLog(['OAuthKit.configure', configuration, reset])
    return Config.configure(configuration, reset)
  }

  /**
   * Syncronizes storages with local state
   */
  async syncState(notify = true) {
    debugLog(['OAuthKit.syncState'])

    const authData = await StorageManager.getAuthInfo()
    const userData = await StorageManager.getUserData()
    const lastActivity = await StorageManager.getLastActivity()
    const lastAuthCodeFlowAt = await StorageManager.getLastOAuthAuthorizationCodeFlowAt()
    const accessAllowed = await this.isAccessAllowed()
    const signedIn = await this.isTokenLocalValid()

    this.state = produce(this.state, (draft) => {
      draft.authData = authData
      draft.userData = userData
      draft.lastActivityAt = lastActivity
      draft.lastOAuthAuthorizationCodeFlowAt = lastAuthCodeFlowAt
      draft.accessAllowed = accessAllowed
      draft.signedIn = signedIn
    })

    if (notify) this.notifyChange()
  }

  /**
   * Returns the full OAuthKitState
   */
  getState() {
    return this.state
  }

  /**
   * Returns the current config
   */
  getConfig(): IConfig {
    return Config.get()
  }

  /**
   * Returns stored auth/token data
   */
  getAuthInfo(): StorageAuthData {
    return this.getState().authData ?? {}
  }

  /**
   * Returns stored user data
   */
  getUserData(): Partial<StorageUserData> {
    return this.getState().userData ?? {}
  }

  /**
   * Returns stored last activity as Date
   */
  getLastActivity(): Date | undefined {
    return this.getState().lastActivityAt
  }

  /**
   * Returns stored last time the oauth callback has been
   * triggered (which can be treated as last sign in date).6
   */
  getLastOAuthAuthorizationCodeFlow(): Date | undefined {
    return this.getState().lastOAuthAuthorizationCodeFlowAt
  }

  /**
   * Register callback to get notified once some information are changed
   * in the storage(s).
   */
  subscribeChange(callback: OnUpdateCallback): void {
    MessageBroker.subscribe(this.getUpdateChannelName(), callback)
  }

  /**
   * @deprecated Use {@link subscribeChange}
   */
  addUpdateListener(callback: OnUpdateCallback): void {
    this.subscribeChange(callback)
  }

  /**
   * Remove a registered update callback.
   */
  unsubscribeChange(callback: OnUpdateCallback): void {
    MessageBroker.unsubscribe(this.getUpdateChannelName(), callback)
  }

  /**
   * @deprecated Use {@link unsubscribeChange}
   */
  removeUpdateListener(callback: OnUpdateCallback): void {
    this.unsubscribeChange(callback)
  }

  /**
   * Notify listeners for changes
   */
  notifyChange(): void {
    MessageBroker.message(this.getUpdateChannelName())
  }

  /**
   * @deprecated Use {@link notifyChange}
   */
  notifyListeners(): void {
    this.notifyChange()
  }

  /**
   * Register callback to get notified in case authentication state
   * is not valid and sign in is required.
   */
  addAuthRequiredListener(callback: OnAuthRequiredCallback): void {
    MessageBroker.subscribe(this.getAuthRequiredChannelName(), callback)
  }

  /**
   * Remove a registered auth required callback.
   */
  removeAuthRequiredListener(callback: OnAuthRequiredCallback): void {
    MessageBroker.unsubscribe(this.getAuthRequiredChannelName(), callback)
  }

  /**
   * Notify listeners for changes
   */
  notifyAuthRequiredListeners(): void {
    MessageBroker.message(this.getAuthRequiredChannelName())
  }

  /**
   * Checks the local cookie token data and identifies if the token is still
   * valid. It's only the local analysis of the existing data. A server side
   * invalidation will not be recognized.
   */
  async isTokenLocalValid(): Promise<boolean> {
    debugLog(['OAuthKit.isTokenLocalValid'])

    const token = await StorageManager.getToken()
    const {
      expiresAt,
      scope,
    } = await StorageManager.getAuthInfo()
    const { clientScope } = Config.get()

    if (!isPresent(token)) return false
    if (!isNumGt0(expiresAt)) return false
    if (!isPresent(scope)) return false
    if (scope !== clientScope) return false

    const now = parseInt(`${new Date().getTime() / 1000}`, 10)

    return now < expiresAt
  }

  /**
   * Returns the seconds until expiration of the current token.
   * If any information is missing, 0 will be returned.
   */
  async getExpiresInSeconds(): Promise<number> {
    debugLog(['OAuthKit.getExpiresInSeconds'])

    if (await this.isTokenLocalValid() === false) return 0

    const { expiresAt } = await StorageManager.getAuthInfo()

    if (!isNumGt0(expiresAt)) return 0

    return expiresAt - this.nowSeconds()
  }


  /**
   * Fetches the OAuth JWT from the id service for the passed authorization code.
   * The returned JWT data will be passed to JWT for further persistance and
   * management.
   *
   * @param code Authorization code
   * @param notify Notify listeners for updated data.
   */
  async fetchAccessToken(
    code: string,
    notify = true
  ): Promise<boolean> {
    debugLog(['OAuthKit.fetchAccessToken', code, notify])

    const data = await Api.fetchAccessToken(code)

    if (data instanceof Error) return false

    await StorageManager.setAuthInfoByOAuthTokenData(data)
    await StorageManager.setLastOAuthAuthorizationCodeFlowAt()
    await PkceHelper.resetData()
    await this.syncState(notify)

    return true
  }

  /**
   * Fetches the OAuth JWT from the id service for the persisted refresh_token.
   *
   * The returned JWT data will be passed to JWT for further persistance and
   * management.
   *
   * @param notify Notify listeners for updated data.
   */
  async refetchAccessToken(
    notify = true
  ): Promise<boolean> {
    debugLog(['OAuthKit.refetchAccessToken', notify])

    const data = await Api.refetchAccessToken()

    if (data === false || data instanceof Error) return false

    await StorageManager.setAuthInfoByOAuthTokenData(data)
    await PkceHelper.resetData()
    await this.syncState(notify)

    return true
  }

  /**
   * Checks if the threshold is passed to call `refetchOAuthToken` afterwards.
   * Otherwise it will just return false.
   * @see {@link refetchAccessToken}
   */
  async refetchAccessTokenIfNecessary(): Promise<boolean> {
    debugLog(['OAuthKit.refetchAccessTokenIfNecessary'])

    const refetchToken = await StorageManager.getRefreshToken()
    if (!isPresent(refetchToken)) return false

    const { refreshTokenThresholdSeconds } = Config.get()

    if (!isNumGt0(refreshTokenThresholdSeconds)) {
      handleError(new OAuthError(
        'Config.get().refreshTokenThresholdSeconds is missing',
        'INCOMPLETE_CONFIGURATION'
      ))
      return false
    }

    const secsToExp = await this.getExpiresInSeconds()

    if (!isNumGt0(secsToExp)) return false
    if (secsToExp > refreshTokenThresholdSeconds) return false

    return await this.refetchAccessToken()
  }

  /**
   * Fetches the user information from id server and stores it into a cookie.
   *
   * @param notify Notify listeners for updated data.
   */
  async fetchUserInfo(
    notify = true
  ): Promise<boolean> {
    debugLog(['OAuthKit.fetchUserInfo', notify])

    if (await this.isTokenLocalValid() === false) {
      handleError(new OAuthError('Token data invalid', 'LOCAL_TOKEN_INVALID'))
      return false
    }

    const data = await Api.fetchUserInfo()

    if (data instanceof Error) return false

    await StorageManager.setUserDataByOAuthUserData(data)
    await this.syncState(notify)

    return true
  }

  /**
   * Runs fetchOAuthToken and fetchUserInfo
   *
   * @param code Authorization code
   */
  async fetchOAuthTokenAndData(
    code: string
  ): Promise<void> {
    debugLog(['OAuthKit.fetchOAuthTokenAndData', code])

    await this.fetchAccessToken(code, false)
    await this.fetchUserInfo(false)
    await this.syncState()
  }

  /**
   * Delete all auth related information. User information
   * are still stored and can be removed via {@link clearUserInfo}
   */
  async clearAuthData(): Promise<void> {
    debugLog(['OAuthKit.clearAuthData'])

    await this.localSignOut()
    await this.syncState()
  }

  /**
   * Delete all user related information. Auth information
   * are still stored and can be removed via {@link clearAuthData}
   */
  async clearUserInfo(): Promise<void> {
    debugLog(['OAuthKit.clearUserInfo'])

    await StorageManager.resetUserInfo()
    await this.syncState()
  }

  /**
   * Requests the server to check if the token is valid on server side.
   * If everything is valid, the JWT data ist updated.
   */
  async isTokenRemoteValid(): Promise<boolean> {
    debugLog(['OAuthKit.isTokenRemoteValid'])

    if (await this.isTokenLocalValid() === false) {
      handleError(new OAuthError('Token data invalid', 'LOCAL_TOKEN_INVALID'))
      return false
    }

    try {
      const data = await Api.fetchTokenInfo()

      if (data instanceof Error) return false

      if ((!isValidArray(data?.scope) && !isPresent(data?.scope))
          || !isNumGt0(data?.expires_in)
          || !isNumGt0(data?.created_at)) {
        await this.localSignOut()
        return false
      }

      await StorageManager.setAuthInfoByOAuthTokenInfoData(data)
      await this.syncState()

      return true
    } catch (e) {
      const err = e as OAuthError

      if (err?.reason === 'OAUTH_RESPONSE_TOKEN_INVALID') {
        await this.localSignOut()
        return false
      }

      handleError(e as Error)

      return false
    }
  }

  /**
   * Based on {@link ConfigConfigureOptions.acceptedAccountTypes} it
   * returns if the access to your application is allowed or not. If
   * the user is not signed in (no valid local token), the functions
   * returns `false` as well. Just to make sure the scope is also checked.
   */
  async isAccessAllowed(): Promise<boolean> {
    debugLog(['OAuthKit.isAccessAllowed'])

    const tokenData = this.getAuthInfo()
    const scope = this.getConfig().clientScope
    const currentAccount = this.getUserData().currentAccount
    let acceptedAccountTypes = this.getConfig().acceptedAccountTypes

    if (!isValidArray(acceptedAccountTypes)) {
      acceptedAccountTypes = [acceptedAccountTypes as AccountType]
    }

    if (tokenData?.scope !== scope) return false
    if (includes(acceptedAccountTypes, 'all')) return true

    return Promise.resolve(includes(acceptedAccountTypes, currentAccount?.type))
  }

  /**
   * @see {@link StorageManager.setLastActivity}
   */
  async setLastActivity(): Promise<Date | undefined> {
    debugLog(['OAuthKit.setLastActivity'])

    const result = await StorageManager.setLastActivity()
    await this.syncState()
    return result
  }

  /**
   * Tests if a full sign in is required or if e.g. an
   * auto redirect iframe refresh would be enough.
   * @param _timeComparator Pass the "current" date. Usually you don't
   *                        need to set this. It is mainly for testing
   *                        purposes.
   */
  async isFullSignInRequired(
    _timeComparator = new Date()
  ): Promise<boolean> {
    debugLog(['OAuthKit.isFullSignInRequired'])

    if (await this.isTokenLocalValid() === false) return true

    const {
      signInRequiredAfterSeconds,
      signInRequiredAfterInactivitySeconds,
    } = Config.get()
    const lastOAuthCallbackAt = await StorageManager.getLastOAuthAuthorizationCodeFlowAt()
    const lastActivityAt = await StorageManager.getLastActivity()
    const now = _timeComparator
    const signInRequiredAfter = isNumGt0(signInRequiredAfterSeconds)
      ? signInRequiredAfterSeconds * 1000
      : -1
    const signInRequiredAfterInactivity = isNumGt0(signInRequiredAfterInactivitySeconds)
      ? signInRequiredAfterInactivitySeconds * 1000
      : -1

    if (!isValidDate(now)) return true
    if (!isValidDate(lastOAuthCallbackAt)) return true
    if (!isValidDate(lastActivityAt)) return true

    if (isNumGt0(signInRequiredAfter) &&
        now.getTime() - lastOAuthCallbackAt.getTime() > signInRequiredAfter) {
      return true
    }

    if (isNumGt0(signInRequiredAfterInactivity) &&
        now.getTime() - lastActivityAt.getTime() > signInRequiredAfterInactivity) {
      return true
    }

    return false
  }

  /**
   * Locally signs out the user. The session at the id service
   * remains open, the local token will be reset.
   */
  async localSignOut(): Promise<void> {
    debugLog(['OAuthKit.localSignOut'])

    await StorageManager.resetToken()
    await StorageManager.resetRefreshToken()
    await StorageManager.resetAuthInfo()
    await this.syncState()
  }

  /**
   * Returns true/false whether there is a refresh token or not
   */
  async hasRefreshToken(): Promise<boolean> {
    debugLog(['OAuthKit.hasRefreshToken'])

    const token = await StorageManager.getRefreshToken()
    return isPresent(token)
  }

  /**
   * Return the data you stored with {@link setAfterSignInData}
   * before staring the auth process.
   */
  async getAfterSignInData<T>(): Promise<T | undefined> {
    return await StorageManager.getReturnData<T>()
  }

  /**
   * Store relevant information that will be returned once
   * the sign in with oauth callback is done.
   */
  async setAfterSignInData<T>(data: T): Promise<void> {
    const result = await StorageManager.setReturnData<T>(data)
    await this.syncState()
    return result
  }

  /**
   * Return the ID service sign in url
   */
  async getSignInUri(options?: NavigateSignInOptions): Promise<string> {
    return await UrlBuilder.buildOAuthSignInUri(options)
  }

  /**
   * Return the ID service sign up url
   */
  async getSignUpUri(options?: NavigateSignInOptions): Promise<string> {
    return await UrlBuilder.buildOAuthSignUpUri(options)
  }

  /**
   * Return the ID service sign out url
   */
  async getSignOutUri(): Promise<string> {
    return Promise.resolve(UrlBuilder.buildSignOutUri())
  }

  getUpdateChannelName() {
    return `${this.queuePrefix}-update`
  }

  getAuthRequiredChannelName() {
    return `${this.queuePrefix}-authRequired`
  }

  /**
   * @private
   */
  private nowSeconds(): number {
    return Math.floor(Date.now() / 1000)
  }

  /**
   * @private
   * Reset local data for testing purposes
   */
  _testReset() {
    MessageBroker.unsubscribeAll(this.getUpdateChannelName())
    MessageBroker.unsubscribeAll(this.getAuthRequiredChannelName())
  }

  /**
   * @private
   * Return the callbacks array
   */
  _testGetUpdateCallbacks() {
    return MessageBroker.getChannel(this.getUpdateChannelName())
  }

  /**
   * @private
   * Return the callbacks array
  */
  _testGetAuthRequiredListener() {
    return MessageBroker.getChannel(this.getAuthRequiredChannelName())
  }
}

//
// Singleton
const OAuthKitSingleton = new OAuthKit()
export default OAuthKitSingleton
