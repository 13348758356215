/**
 * Config module for the oauth-kit. Have a look at the default
 * values to know what is possible and/or required to configure.
 */

import DummyStorage from './storage/DummyStorage'

import type { Config as IConfig, ConfigConfigureOptions } from './Types'

export const DEFAULT_CONFIG: IConfig = {
  clientId: '',
  clientSecret: undefined,
  idHost: '',
  idApiHost: '',
  redirectUri: '',
  clientScope: 'public',
  acceptedAccountTypes: 'all',
  idApiOAuthTokenPath: '/oauth/token',
  idApiOAuthTokenInfoPath: '/oauth/token/info',
  idApiOAuthMePath: '/api/v1/me',
  idSignInPath: '/sign-in',
  idSignUpPath: '/sign-up',
  idSignOutPath: '/sign-out',
  signInRequiredAfterSeconds: 24 * 60 * 60, // 24h as default
  signInRequiredAfterInactivitySeconds: 30 * 60, // 30min as default
  refreshTokenThresholdSeconds: 1 * 60 * 60, // 1h as default
  encryptionSecret: undefined,
  authStorage: new DummyStorage(),
  userInfoStorage: new DummyStorage(),
  debug: false,
  debugHandler: (message: string) => console.debug(message),
  exceptionHandler: (error: Error) => { throw error },
}

// Config object
let config = { ...DEFAULT_CONFIG }

/**
 * Get the config object
 */
export function get(): IConfig {
  return config
}

/**
 * Merges the default config with your passed configuration
 * object and saves it as current config.
 * @param cfg Your configuration
 * @return New current config
 */
export function configure(cfg: ConfigConfigureOptions, reset = false): IConfig {
  config = reset
    ? { ...DEFAULT_CONFIG, ...cfg }
    : { ...DEFAULT_CONFIG, ...config, ...cfg }
  return config
}

/**
 * @private
 * Reset config to defaultConfig for testing purposes
 */
export function _testReset(
  newConfig: ConfigConfigureOptions = {} as ConfigConfigureOptions
): void {
  configure(newConfig, true)
}

// default exports
const Config = {
  DEFAULT_CONFIG,
  get,
  configure,
}

export default Config
